body {
  margin: 0;
  background-color: #434343 !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #000 !important;
}

.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #f44336 !important;
}

.enter_amount div {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 2px;

  .enter_amount_box {
    width: 75%;

    .MuiFormHelperText-root.Mui-error {
      margin-top: 0;
    }
  }
}

::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-thumb {
  background: #434343;
}

.main_layouts {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background-color: #e5e5e5;
  align-items: center;
  position: relative;
  cursor: pointer;
  width: 360px;
  height: 640px;
  overflow: hidden;

}

.agent_layout {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #D9D9D9;
  color: #000000;
  font-size: 16px;

  .lm_btn {
    height: 48px;
    background-color: var(--button-bg-gray);
    color: white;
    padding: 0 10px;
    border-radius: var(--border-radius);
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: var(--box-bg-gray);
    }
    .icon {
      width: 40px;
      padding: 0;
    }
  }

  .submit_btn {
    height: 64px;
    border-radius: 32px;
    padding: 0 48px;
    background-color: var(--button-bg-gray);
    color: white;
    font-size: 16px;
    white-space: nowrap;
    &:hover {
      background-color: var(--box-bg-gray);
    }
  }

  .agent_body {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 10px 0;
    gap: 10px;
    overflow: auto;
    flex: 1 1 auto;
    max-width: 600px;
    margin: auto;

    &.full_width {
      max-width: none;
    }
  }

  .message_box {
    display: flex;
    flex: 1 1 auto;
    min-height: 150px;
    height: 100%;
    padding: 10px;
    border: 1px solid var(--box-bg-gray);
    color: var(--box-bg-gray);
    border-radius: var(--border-radius);

    span {
      display: flex;
      width: 100%;
    }
  }

  .row_details {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;

    .row_details_inner {
      display: flex;
      align-items: center;
      width: 100%;
      height: 40px;

      .row_details_text {
        font-size: 16px;
        display: flex;
        align-items: center;
        width: 50%;

        svg {
          color: #000;
          margin-left: 10px;
          font-size: 18px;
        }
      }
    }
  }

  .header_box {
    background-color: var(--box-bg-gray);
    color: var(--accent-color);
    height: 50px;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    &.space_between {
      justify-content: space-between;
      padding: 0 10px;
    }
  }

  .game_details_box {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;

    .game_details_box_inner {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 20px;

      .details_box_btn {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
      }
    }
  }

  .sub_header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px 0;

    .sub_header_text {
      width: 50%;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &:nth-child(2) {
        justify-content: flex-end;
      }
    }
  }

  .input_form_box {
    //flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--box-bg-gray);
    border-radius: var(--border-radius);
    padding: 10px;
    .input_form_box_inner {
      flex: 1 1 auto;
      overflow: auto;
    }
  }
  .form_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  .input_form {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    min-height: 48px;
    & > * {
      width: 100%;
      input, textarea {
        text-align: center;
      }
    }
  }
  .MuiFormControl-root {
    display: flex;
    justify-content: center;
    width: 100%;
    & > * > * {
      position: relative;
      z-index: 2;
    }
    input {
      height: 48px;
      padding: 0 24px;
      font-size: 20px;
      border-radius: 24px;
    }
    textarea {
      padding: 6px 24px;
      font-size: 20px;
      border-radius: 24px;
    }
    fieldset {
      top: 0;
      border-radius: 24px;
      background-color: #FFFFFF;
      z-index: 1 !important;
      position: absolute;
    }
  }
}
