.horizontal_list_dialog {
  display: flex;
  flex-wrap: nowrap;

  .list_inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 calc(var(--unit) * 4);

    .players_name,
    .players_amount {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      .player_text,
      .amount_text {
        color: var(--accent-color);
        font-size: 100%;
        line-height: 1.6;
      }
    }

    &.list_title {

    }
  }

  .list_main {
    display: flex;
    overflow: auto;
    align-items: center;
    height: 100%;

    .list_inner {
      align-items: flex-end;

      .players_name,
      .players_amount {
        justify-content: flex-end;

        .player,
        .amount {
          color: #fff;
          font-size: 80%;
        }
      }
    }
  }
}
