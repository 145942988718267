.game_details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  height: calc(var(--unit) * 30);
  gap: calc(var(--unit) * 1.5);
  position: relative;

  .game_details_main {
    display: flex;
    width: 30%;
    height: calc(var(--unit) * 18);
    border-radius: var(--border-radius);

    .game_details_inner {
      display: flex;
      flex-direction: column;
      width: 100%;
      background: var(--box-bg-gray);
      border-radius: var(--border-radius);
      align-items: center;
      justify-content: space-evenly;

      .game_details_text {
        line-height: 1;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        color: var(--accent-color);
        width: 100%;
      }

      .game_details_value {
        height: 40%;
        font-size: 125%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #FFFFFF;
        width: 100%;
      }
    }
  }
}
