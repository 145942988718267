.display_name {
  background-color: var(--app-bg);
  .page_content {
    gap: 20px;
  }

  .display_name_inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--box-bg-gray);
    border-radius: 10px;
    padding: 10px 15px;
    gap: 70px;

    .display_name_info {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 30px;

      .password_text {
        font-size: 20px;
        display: flex;
        align-items: center;
        color: #FF9900;
      }

      .info_text {
        font-size: 20px;
        color: #FFFFFF;
      }

      .display_name_btn {
        width: auto;
        height: 55px;
        padding: 0 20px;
        min-width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #666666;
        border-radius: 25px;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #FFFFFF;
        text-transform: capitalize;
      }
    }
  }

  .display_name-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    margin-top: 20px;
    padding: 0 20px;

    .display_name_inner1 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;
    }

    .info_text {
      font-size: 20px;
      color: #FFFFFF;
    }

    .info_text1 {
      font-size: 16px;
      color: #FFFFFF;
      text-align: center;
    }

    .input_box {
      width: 100%;
      height: 100vh;
      max-height: 70px;
      max-width: 450px;
      border-radius: 50px;
      border: 1px solid #434343;
      padding: 10px;
      color: #000 !important;
      text-align: center;
      font-size: 20px;
      outline: unset;


      &:focus {
        border: 2px solid #ff9900;
      }
    }

    .login_btn {
      width: 100%;
      max-width: 200px;
      height: 100vh;
      max-height: 70px;
      background: #434343;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      color: #f5f5f5;
      border: unset;
      text-transform: capitalize;

    }
  }


}

@media only screen and (min-width: 300px) and (min-height: 430px) {
  .display_name {
    .display_name_inner {
      padding: 10px;
      gap: 30px;

      .display_name_info {
        gap: 10px;

        .password_text,
        .info_text,
        .display_name_btn {
          font-size: 15px;
        }

        .display_name_btn {
          height: 35px;
        }
      }
    }

    .display_name-div {
      gap: 10px;

      .input_box,
      .info_text {
        max-height: 35px;
        font-size: 15px;
      }

      .login_btn {
        max-width: 120px;
        max-height: 35px;
        font-size: 15px;
      }
    }
  }
}
