html,
body {
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #434343 !important;
}

.col_center {
  justify-content: center !important;
}

.wheel {
  width: 200px;
  height: 200px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(-29.5deg);
  border-radius: 50%;
  outline: 1px solid #373737;
}

.outerRim {
  background-image: url("../../../../public/img/Roulette_Wheel.png");
  background-repeat: round;
  border-radius: 100%;
  background-color: #797878;
  position: relative;
  height: 290px;
  width: 290px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sect {
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 100%;
  clip: rect(0px, 200px, 200px, 100px);
}

.double,
.single {
  position: absolute;
  z-index: 1;
  color: #fff;
  font-size: 14px;
  transform: rotate(8deg);
}

.double {
  left: 106px;
  margin-top: 11px;
}

.single {
  left: 109px;
  margin-top: 11px;
}

.block {
  transition: all 1s;
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 100%;
  clip: rect(0px, 100px, 200px, 0px);
}

#sect1 .block {
  background-color: var(--game-dark-green);
  transform: rotate(18.95deg);
}

#sect2 .block,
#sect4 .block,
#sect6 .block,
#sect8 .block,
#sect10 .block,
#sect12 .block,
#sect14 .block,
#sect16 .block,
#sect18 .block,
#sect20 .block,
#sect22 .block,
#sect24 .block,
#sect26 .block,
#sect28 .block,
#sect30 .block,
#sect32 .block,
#sect34 .block,
#sect36 .block {
  background-color: var(--game-red);
  transform: rotate(18.95deg);
}

#sect3 .block,
#sect5 .block,
#sect7 .block,
#sect9 .block,
#sect11 .block,
#sect13 .block,
#sect15 .block,
#sect17 .block,
#sect19 .block,
#sect21 .block,
#sect23 .block,
#sect25 .block,
#sect27 .block,
#sect29 .block,
#sect31 .block,
#sect33 .block,
#sect35 .block,
#sect37 .block {
  background-color: var(--game-black);
  transform: rotate(18.95deg);
}

.pocketsRim {
  position: absolute;
  width: 110px;
  height: 110px;
  border-radius: 100%;
  border: 1px solid var(--roulette-border);
}

.ballTrack {
  width: 100px;
  height: 100px;
  position: absolute;
  border-radius: 100%;
  z-index: 2;
}

.ball {
  background-color: #fff;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  position: relative;
  left: 68px;
  top: 9px;
}

.pockets {
  position: absolute;
  width: 130px;
  height: 130px;
  border-radius: 100%;
  opacity: 0.5;
}

.cone {
  position: absolute;
  height: 60px;
  width: 60px;
  border: 3px solid #000;
  border-radius: 100%;
  background: #1b1b1b;
  box-shadow: rgb(255 248 248 / 56%) 0 14px 142px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(29deg);
}

.cone span {
  font-size: 30px;
  /*color: red;*/
}

/*wheel*/
@keyframes wheelRotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

@keyframes ballRotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-359deg);
  }
}

#sect1 {
  transform: rotate(18.95deg);
}

#sect2 {
  transform: rotate(37.9deg);
}

#sect3 {
  transform: rotate(56.85deg);
}

#sect4 {
  transform: rotate(75.8deg);
}

#sect5 {
  transform: rotate(94.75deg);
}

#sect6 {
  transform: rotate(113.7deg);
}

#sect7 {
  transform: rotate(132.65deg);
}

#sect8 {
  transform: rotate(151.6deg);
}

#sect9 {
  transform: rotate(170.55deg);
}

#sect10 {
  transform: rotate(189.5deg);
}

#sect11 {
  transform: rotate(208.45deg);
}

#sect12 {
  transform: rotate(227.4deg);
}

#sect13 {
  transform: rotate(246.35deg);
}

#sect14 {
  transform: rotate(265.3deg);
}

#sect15 {
  transform: rotate(284.25deg);
}

#sect16 {
  transform: rotate(303.2deg);
}

#sect17 {
  transform: rotate(322.15deg);
}

#sect18 {
  transform: rotate(341.1deg);
}

#sect19 {
  transform: rotate(360.05deg);
}

.border_line {
  background-color: var(--roulette-border);
  height: 1px;
  width: 200px;
  z-index: 58;
  position: absolute;
  top: 81px;
  left: -6px;
  transform: rotate(71deg);
}



@media only screen and (min-width: 360px) and (min-height: 540px) {
  .outerRim {
    transform: scale(1.2);
    transform-origin: top;
  }
}

@media only screen and (min-width: 420px) and (min-height: 630px) {
  .outerRim {
    transform: scale(1.35);
    transform-origin: top;
  }
}

@media only screen and (min-width: 480px) and (min-height: 720px) {
  .outerRim {
    transform: scale(1.5);
    transform-origin: top;
  }
}

@media only screen and (min-width: 560px) and (min-height: 840px) {
  .outerRim {
    transform: scale(1.75);
    transform-origin: top;
  }
}

@media only screen and (min-width: 640px) and (min-height: 960px) {
  .outerRim {
    transform: scale(2);
    transform-origin: top;
  }
}
