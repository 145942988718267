.agent_home {
  .slider_box2 {
    width: 100%;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background: var(--box-bg-gray);
    color: #FFFFFF;
    position: relative;
    padding: 10px 20px;
    border-radius: 10px;
    overflow: hidden;
    gap: 5px;

    .slider_box2_header {
      font-size: 24px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #e5e5e5;

      span {
        color: #ff0000;
      }
    }

    .Operator_Name_text {
      font-size: 20px;
      display: flex;
      align-items: center;
      text-align: center;
      color: var(--accent-color);
    }

    .messages_main {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      overflow: auto;

      .messages {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 5px 10px !important;
        background: var(--button-bg-gray);
        border-radius: 10px;
        gap: 5px !important;

        .messages_inner {
          display: flex;
          align-items: center;
          justify-content: space-between;

          span {
            width: 50%;
            font-size: 16px;
            display: flex;
            align-items: center;
            color: #ffffff;
          }
        }

        .messages_inner1 {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .message_text {
            width: 80%;
            font-size: 16px;
            display: flex;
            align-items: center;
            color: #ffffff;
          }

          .bag {
            background-color: red;
            border-radius: 100%;
            padding: 2px;
            font-size: 14px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #ffffff;
          }
        }
      }
    }
  }

  .user_names {
    width: 100%;
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 0 auto;

    span {
      width: 50%;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      &:nth-child(1) {
        color: var(--accent-color);
      }
      &.wight_color {
        color: #ffffff;
      }
    }

    &.balance {
      background-color: black;
      border-radius: var(--border-radius);
    }
  }
}
