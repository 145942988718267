.homeroulette {
  background: var(--game-bg);

  .header {
    background-color: #000000;
  }
}

#drag_drop {
  display: none;
  position: fixed;
  right: 8px;
  top: -30px;
  z-index: 20000;
  font-size: 25px;
  color: #000000;
  -webkit-text-stroke: 1px #ffffff;
}

.no_more_bet {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.roulettewheel_div {
  position: absolute;
  top: calc(var(--unit) * 30.2);
  right: 0;
  bottom: calc(var(--unit) * 5);
  left: 0;
  z-index: 100;
}
