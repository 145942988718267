.messages_body {
  .message_list {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .message {
      background-color: var(--box-bg-gray);
      border-radius: var(--border-radius);
      color: #FFFFFF;
      padding: 10px 20px;

      .message_meta {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        .sender {
          font-weight: 700;
        }
      }
    }
  }
}
