.login {
    background-color: var(--game-bg);

    .page_content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 25px;
    }

    .luckyminute {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        background: #434343;
        border-radius: 30px;
        max-width: 500px;
        max-height: 250px;

        .luckyminute_text {
            font-size: 32px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #e5e5e5;

            span {
                color: #ff0000;
            }
        }
    }

    .profile_pic {
        width: 100%;
        height: 100%;
        max-width: 100px;
        max-height: 100px;
        display: block;
    }

    .login_div {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 30px;

        .input_box {
            width: 100%;
            height: 100vh;
            max-height: 70px;
            max-width: 450px;
            border-radius: 50px;
            border: 1px solid #434343;
            padding: 10px;
            color: #000 !important;
            text-align: center;
            font-size: 28px;
            outline: unset;


            &:focus {
                border: 2px solid #ff9900;
            }
        }

        .login_btn {
            width: 100%;
            max-width: 200px;
            height: 100vh;
            max-height: 70px;
            background: #434343;
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 28px;
            color: #f5f5f5;
            border: unset;
            text-transform: capitalize;

        }
    }
}

@media only screen and (min-width: 300px) and (min-height: 430px) {
    .login {
        .luckyminute {
            max-height: 100px;
            border-radius: 10px;

            .luckyminute_text {
                font-size: 24px;
            }
        }

        .profile_pic {
            max-width: 80px;
            max-height: 80px;
        }

        .login_div {
            gap: 20px;

            .input_box {
                max-height: 35px;
                font-size: 16px;
            }

            .login_btn {
                max-width: 120px;
                max-height: 35px;
                font-size: 16px;
            }
        }
    }
}

@media only screen and (min-width: 360px) and (min-height: 485px) {
    .login {
        .luckyminute {
            max-height: 120px;

            .luckyminute_text {
                font-size: 28px;
            }
        }

        .profile_pic {
            max-width: 90px;
            max-height: 90px;
        }

        .login_div {
            gap: 20px;

            .input_box {
                max-height: 40px;
                font-size: 18px;
            }

            .login_btn {
                max-width: 140px;
                max-height: 40px;
                font-size: 18px;
            }
        }
    }
}

@media only screen and (min-width: 300px) and (min-height: 550px) {
    .login {
        .luckyminute {
            max-height: 120px;

            .luckyminute_text {
                font-size: 28px;
            }
        }

        .profile_pic {
            max-width: 90px;
            max-height: 90px;
        }

        .login_div {
            gap: 20px;

            .input_box {
                max-height: 40px;
                font-size: 18px;
            }

            .login_btn {
                max-width: 140px;
                max-height: 40px;
                font-size: 18px;
            }
        }
    }
}

@media only screen and (min-width: 360px) and (min-height: 620px) {
    .login {
        .page_content {
            gap: 30px;
        }
        .luckyminute {
            max-height: 140px;

            .luckyminute_text {
                font-size: 32px;
            }
        }

        .profile_pic {
            max-width: 100px;
            max-height: 100px;
        }

        .login_div {
            gap: 30px;

            .input_box {
                max-height: 50px;
                font-size: 20px;
            }

            .login_btn {
                max-width: 150px;
                max-height: 50px;
                font-size: 20px;
            }
        }
    }
}

@media only screen and (min-width: 390px) and (min-height: 660px) {
    .login {
        .luckyminute {
            max-height: 150px;

            .luckyminute_text {
                font-size: 40px;
            }
        }

        .profile_pic {
            max-width: 120px;
            max-height: 120px;
        }

        .login_div {
            gap: 25px;

            .input_box {
                max-height: 50px;
                font-size: 22px;
            }

            .login_btn {
                max-width: 160px;
                max-height: 50px;
                font-size: 22px;
            }


        }
    }
}

@media only screen and (min-width: 420px) and (min-height: 700px) {
    .login {
        .page_content {
            gap: 40px;
        }
        .luckyminute {
            max-height: 155px;

            .luckyminute_text {
                font-size: 40px;
            }
        }

        .profile_pic {
            max-width: 130px;
            max-height: 130px;
        }

        .login_div {
            gap: 30px;

            .input_box {
                max-height: 55px;
                font-size: 24px;
            }


            .login_btn {
                max-width: 170px;
                max-height: 55px;
                font-size: 24px;
            }


        }
    }
}

@media only screen and (min-width: 540px) and (min-height: 930px) {
    .login {
        .page_content {
            gap: 60px;
        }
        .luckyminute {
            max-height: 180px;

            .luckyminute_text {
                font-size: 50px;
            }
        }

        .profile_pic {
            max-width: 165px;
            max-height: 165px;
        }

        .login_div {
            gap: 40px;

            .input_box {
                max-height: 65px;
                font-size: 26px;
            }

            .login_btn {
                max-width: 180px;
                max-height: 65px;
                font-size: 26px;
            }


        }
    }
}

@media only screen and (min-width: 720px) and (min-height: 1240px) {
    .login {
        .page_content {
            gap: 80px;
        }
        .luckyminute {
            max-height: 220px;

            .luckyminute_text {
                font-size: 55px;
            }
        }

        .profile_pic {
            max-width: 200px;
            max-height: 200px;
        }

        .login_div {
            gap: 40px;

            .input_box {
                max-height: 70px;
                font-size: 28px;
            }

            .login_btn {
                max-width: 180px;
                max-height: 70px;
                font-size: 28px;

            }
        }
    }



}
