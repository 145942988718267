.change_password {
  background-color: var(--app-bg);

  .change_password_inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--box-bg-gray);
    border-radius: 10px;
    padding: 10px 15px;
    gap: 70px;

    .change_password_info {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 30px;

      .password_text {
        font-size: 20px;
        display: flex;
        align-items: center;
        color: #FF9900;
      }

      .info_text {
        font-size: 20px;
        color: #FFFFFF;
      }

      .change_password_btn {
        width: auto;
        height: 55px;
        padding: 0 20px;
        min-width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #666666;
        border-radius: 25px;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #FFFFFF;
        text-transform: capitalize;
      }
    }
  }



}

@media only screen and (min-width: 300px) and (min-height: 430px) {
  .change_password {
    .change_password_inner {
      padding: 10px;
      gap: 30px;

      .change_password_info {
        gap: 10px;

        .password_text,
        .info_text,
        .change_password_btn {
          font-size: 16px;
        }

        .change_password_btn {
          height: 35px;
        }
      }
    }
  }
}

@media only screen and (min-width: 360px) and (min-height: 485px) {
  .change_password {
    .change_password_inner {
      padding: 10px;
      gap: 30px;

      .change_password_info {
        gap: 10px;

        .password_text,
        .info_text,
        .change_password_btn {
          font-size: 18px;
        }

        .change_password_btn {
          height: 40px;
        }
      }
    }
  }
}

@media only screen and (min-width: 300px) and (min-height: 550px) {
  .change_password {
    .change_password_inner {
      padding: 10px;
      gap: 30px;

      .change_password_info {
        gap: 10px;

        .password_text,
        .info_text,
        .change_password_btn {
          font-size: 16px;
        }

        .change_password_btn {
          height: 40px;
        }
      }
    }
  }
}

@media only screen and (min-width: 360px) and (min-height: 620px) {
  .change_password {
    .change_password_inner {
      padding: 10px;
      gap: 50px;

      .change_password_info {

        .password_text,
        .info_text,
        .change_password_btn {
          font-size: 20px;
        }

        .change_password_btn {
          height: 45px;
        }
      }
    }
  }
}

@media only screen and (min-width: 390px) and (min-height: 660px) {
  .change_password {
    .change_password_inner {
      padding: 15px;
      gap: 70px;

      .change_password_info {

        .password_text,
        .info_text,
        .change_password_btn {
          font-size: 20px;
        }

        .change_password_btn {
          height: 45px;
        }
      }
    }
  }
}

@media only screen and (min-width: 420px) and (min-height: 700px) {
  .change_password {
    .change_password_inner {
      padding: 15px;
      gap: 70px;

      .change_password_info {

        .password_text,
        .info_text,
        .change_password_btn {
          font-size: 20px;
        }

        .change_password_btn {
          height: 45px;
        }
      }
    }
  }
}

@media only screen and (min-width: 540px) and (min-height: 930px) {
  .change_password {
    .change_password_inner {
      padding: 15px;
      gap: 90px;

      .change_password_info {

        .password_text,
        .info_text,
        .change_password_btn {
          font-size: 26px;
        }

        .change_password_btn {
          height: 55px;
        }
      }
    }
  }
}


@media only screen and (min-width: 720px) and (min-height: 1240px) {
  .change_password {
    .change_password_inner {
      padding: 20px;
      gap: 100px;

      .change_password_info {

        .password_text,
        .info_text,
        .change_password_btn {
          font-size: 28px;
        }

        .change_password_btn {
          height: 55px;
        }
      }
    }
  }
}
