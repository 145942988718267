.connection_problem {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .connection_problem_inner {
    max-width: 400px;
    max-height: 200px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--dialog-bg);
    text-shadow: var(--dialog-text-shadow);
    border-radius: 20px;
    gap: 10px;
    span {
      font-size: 24px;
      line-height: 29px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: #ff9900;
    }
  }
}
