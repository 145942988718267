.languages {
    background-color: var(--app-bg);

    .languages_inner {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: var(--box-bg-gray);
        border-radius: var(--border-radius);
        padding: 10px 15px;
        gap: 70px;

        .languages_info {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 30px;
            justify-content: space-evenly;
            height: 200px;

            .info_text {
                width: 100%;
                font-weight: 400;
                font-size: 24px;
                line-height: 29px;
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                text-align: center;
                color: #FFFFFF;

                .texts {
                    width: 150px;
                    text-align: start;
                }

                .MuiRadio-colorSecondary.Mui-checked,
                .MuiRadio-root {
                    color: #ffffff;
                }
            }
        }
    }

    .languages_btn {
        width: 100%;
        max-width: 200px;
        height: 100vh;
        max-height: 70px;
        background: #434343;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        color: #f5f5f5;
        border: unset;
        text-transform: capitalize;
        margin-top: 30%;
    }
}
