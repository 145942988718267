.sicbodice_result_container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
}

.dice_main {
  left: 0;
  right: 0;
  top: calc(var(--unit) * 30);
  width: 90%;
  height: calc(var(--unit) * 90);
  margin: auto;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background: var(--dialog-bg);
  text-shadow: var(--dialog-text-shadow);
  border-radius: var(--border-radius);

  .size {
    font-size: 300%;
    line-height: 1;
    height: calc(var(--unit) * 12);
    color: var(--accent-color);
  }

  .dice_div {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    margin-top: calc(var(--unit) * 10);
    ._space3d {
      perspective: none;
    }

    .dice2 {
      margin-top: calc(var(--unit) * -15);
    }

    .defaultFace {
      background-color: #fff !important;
      box-shadow: unset !important;
      border-radius: 10%;
    }

    .defaultFace span {
      box-shadow: unset !important;
      width: 50%;
      height: 50%;
      border-radius: 50%;
      background-color: #000 !important;
      padding: 6px !important;
    }
  }

  .total_number {
    font-size: 200%;
    line-height: 1;
    height: calc(var(--unit) * 8);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: var(--accent-color);
  }

  .total_number_inner {
    font-weight: 900;
    font-size: 300%;
    line-height: 1;
    height: calc(var(--unit) * 12);
    display: flex;
    align-items: center;
    text-align: right;
    justify-content: center;
    color: var(--accent-color);
  }
}
