.trial_home {
  background-color: var(--app-bg);
  .page_content {
    height: 100%;
    justify-content: center;
    gap: 40px;
  }
  .s_placeholder {
    padding: 80px 0;
  }
  button {
    padding: 8px 24px;
    border-radius: var(--border-radius);
    font-weight: 700;
  }
}
