.footer_menu {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 18vw;
  flex: 0 0 18vw;
  background-color: #fff;

  .sub_menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 25%;
    height: 100%;
    gap: 5px;

    img {
      width: calc(var(--unit) * 9);
    }

    .sub_menu_text {
      font-size: calc(var(--unit) * 3);
      display: flex;
      align-items: center;
      text-align: center;
      color: #000;
    }
  }
}


@media (min-aspect-ratio: 1/2) {
  .footer_menu {
    height: 9vh;
    flex: 0 0 9vh;
  }
}
