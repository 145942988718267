.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #000;
    height: calc(var(--unit) * 18);
    z-index: 100;

    .header-button {
        width: calc(var(--unit) * 18);
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .back_btn {
        font-size: calc(var(--unit) * 10);
        color: #fff;
    }
    img {
        width: calc(var(--unit) * 12);
        height: calc(var(--unit) * 12);
    }

    .user_name {
        font-size: calc(var(--unit) * 6);
        display: flex;
        align-items: center;
        text-align: center;
        color: #FFFFFF;
    }

    .game_info {
        font-size: calc(var(--unit) * 10);
        color: #fff;
    }

    #dropdown-basic {
        width: calc(var(--unit) * 18);
        height: calc(var(--unit) * 18);
        background-color: unset;
        border: none;
        box-shadow: unset;
        padding: 0;
        i {
            font-size: calc(var(--unit) * 10);
        }
        &:after {
            display: none;
        }
    }
}

.main_menu {
    .dropdown-item {
        min-width: calc(var(--unit) * 50);
        padding: 0 calc(var(--unit) * 6);
        line-height: calc(var(--unit) * 12);
    }
}
