.start {
  background-color: var(--app-bg);

  .start_inner {
    gap: calc(var(--unit) * 2);

    .start_slider {
      width: 100%;
      height:  calc(var(--unit) * 40);
      background: var(--box-bg-gray);
      border-radius: var(--border-radius);
      position: relative;
      align-items: flex-start;

      .slick-list {
        z-index: 99;
      }

      .slick-dots {
        display: block !important;
        bottom: 0;
        height:  calc(var(--unit) * 10);

        li.slick-active button:before {
          color: var(--accent-color);
        }

        li button:before {
          color: #fff;
          font-size: 10px;
          opacity: unset;
        }
      }

      .slick-next {
        top: unset;
        right: 30%;
        bottom: 0;
        z-index: 100;
        height:  calc(var(--unit) * 10);
        transform: none;
      }

      .slick-prev {
        top: unset;
        left: 30%;
        bottom: 0;
        height:  calc(var(--unit) * 10);
        z-index: 100;
        transform: none;
      }

      .slider_box {
        width: 100%;
        height: calc(var(--unit) * 30);
        display: flex !important;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        background: var(--box-bg-gray);
        position: relative;
        padding: calc(var(--unit) * 3);
        border-radius: var(--border-radius);
        overflow: hidden;

        .slider_box2_header {
          font-size: calc(var(--unit) * 5);
          gap: calc(var(--unit) * 2);
          display: flex;
          align-items: center;
          text-align: center;
          color: #e5e5e5;

          span span {
            color: #ff0000;
          }
        }

        .Operator_Name_text {
          font-size: calc(var(--unit) * 4.5);
          display: flex;
          align-items: center;
          text-align: center;
          color: var(--accent-color);
        }

        .user_names {
          width: 90%;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          margin: 0 auto;

          .wight_color {
            color: #ffffff !important;
          }

          span {
            width: 50%;
            font-size: calc(var(--unit) * 4);
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: var(--accent-color);

            &:nth-child(2) {
              color: #ffffff;
            }
          }
        }

        .messages {
          width: 100%;
          display: flex;
          flex-direction: column;
          padding: calc(var(--unit) * 1) calc(var(--unit) * 2) !important;
          background: #666666;
          border-radius: var(--border-radius);
          gap: calc(var(--unit) * 1) !important;

          .messages_inner {
            display: flex;
            align-items: center;
            justify-content: space-between;

            span {
              font-size: calc(var(--unit) * 4);
              //   line-height: 19px;
              display: flex;
              align-items: center;
              color: #ffffff;
            }
          }

          .messages_inner1 {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .message_text {
              width: 80%;
              font-size: calc(var(--unit) * 4);
              display: flex;
              align-items: center;
              color: #ffffff;
            }

            .bag {
              background-color: red;
              border-radius: 100%;
              color: #fff;
              padding: calc(var(--unit) * 0.5);
              font-size: calc(var(--unit) * 3);
              display: flex;
              align-items: center;
              text-align: center;
            }
          }
        }
      }
    }

    .user_info {
      width: 100%;
      height:  calc(var(--unit) * 20);
      display: flex;
      align-items: center;
      background: #434343;
      border-radius: var(--border-radius);

      .user_balance {
        flex: 0 0 calc((100% - calc(var(--unit) * 20)) / 3);
        height: 100%;
        background: #000000;
        border-radius: var(--border-radius);
        padding: calc(var(--unit) * 1);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;

        .balance_text {
          font-size: calc(var(--unit) * 4);
          display: flex;
          align-items: center;
          text-align: center;
          color: var(--accent-color);

          &:nth-child(2) {
            color: #fff;
          }
        }
      }

      .user_info_inner {
        flex: 1 1 auto;
        height: 100%;
        padding: calc(var(--unit) * 1);
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        .user_detail {
          display: flex;
          align-items: center;
          justify-content: space-evenly;

          .detail_text {
            font-size: calc(var(--unit) * 4);
            display: flex;
            align-items: center;
            text-align: center;
            color: #ffffff;
          }
        }
      }

      .reload_icon_icon svg {
        flex-grow: 1;
        font-size: calc(var(--unit) * 15);
        color: #fff;
      }

      .reload_icon_icon.disable svg {
        cursor: default;
        color: #999;
      }
    }

    .games_option {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: calc(var(--unit) * 2);

      .select_game_card {
        width: 100%;
        height:  calc(var(--unit) * 30);
        display: flex;
        align-items: center;
        justify-content: center;
        gap: calc(var(--unit) * 16);
        border-radius: var(--border-radius);
        padding: calc(var(--unit) * 2);

        &:nth-child(1) {
          background-color: #0f9d58;
        }

        &:nth-child(2) {
          background-color: #db4437;
        }

        &:nth-child(3) {
          background-color: #4285f4;
        }

        &:hover {
          box-shadow: rgb(0 0 0 / 17%) 0px -23px 25px 0px inset,
            rgb(0 0 0 / 15%) 0px -36px 30px 0px inset,
            rgb(0 0 0 / 10%) 0px -79px 40px 0px inset,
            rgb(0 0 0 / 6%) 0px 2px 1px, rgb(0 0 0 / 9%) 0px 4px 2px,
            rgb(0 0 0 / 9%) 0px 8px 4px, rgb(0 0 0 / 9%) 0px 16px 8px,
            rgb(0 0 0 / 9%) 0px 32px 16px;
        }

        img {
          max-width: calc(var(--unit) * 28);
          width: 100%;
          display: block;
        }

        .game_name {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: calc(var(--unit) * 2);
          font-size: calc(var(--unit) * 6);
          color: #fff;

          .baccarat_game_text {
            font-style: italic;
          }

          .sicbo_game_text {
            font-style: italic;
          }

          .roulette_game_text {
            font-style: italic;
          }
        }
      }
    }
  }
}

