#past_results {
  .full_table {
    padding: 0;
    .table_header {
      padding: 0;
      background-color: transparent;
    }
    .table_header_text {
      height: calc(var(--unit) * 8);
      font-size: calc(var(--unit) * 3);
      justify-content: center;
      &:first-child { width: 50%; }
    }


    @keyframes scrollUp {
      0% { margin-top: 0; }
      100% { margin-top: -100%  }
    }

    .table_body_main {
      padding: 0;
      gap: 0;
      &.scrollUp {
        animation: scrollUp 0.5s;
        margin-top: -100%;
      }
    }
    .table_body_list {
      height: calc(var(--unit) * 7);
      min-height: calc(var(--unit) * 7);
      font-size: calc(var(--unit) * 2.5);
    }
    .body_list {
      justify-content: center;
      &:first-child { width: 50%; }
    }
  }
}
