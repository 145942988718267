.draggable {
    width: 90%;
    height: calc(var(--unit) * 26);
    z-index: 1000;
    transform: translate(0px, -196.333px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.slick-dots {
    display: none !important;
}

.slick-list, .slick-slider, .slick-track {
  display: flex;
    align-items: center;
}

.slider_main {
    width: calc(100% - calc(var(--unit) * 32));

    .chip {
        width: calc(var(--unit) * 15) !important;
        height: calc(var(--unit) * 10);
        display: flex !important;
        align-items: center;
        justify-content: center;
        border-radius: var(--border-radius);
        border-style: solid;
        border-color: #FF9900;
        border-width: calc(var(--unit-border) * 4) calc(var(--unit-border) * 1);
        background-color: #D9D9D9;
        color: #000;
        font-size: 125%;
        transition: 0.1s ease-in-out;

        &.long-text span {
            font-size: 80%;
        }

        &.selected-chip {
            width: calc(var(--unit) * 20) !important;
            height: calc(var(--unit) * 14);
            background-color: #434343;
            color: #FFFFFF;
            font-size: 150%;
        }
        &.disabled {
            cursor: default;
            opacity: 0.5;
        }
    }

    .chip1 {
        border-color: #F4B400;
    }

    .chip2 {
        border-color: #4285F4;
    }

    .chip3 {
        border-color: #0F9D58;
    }

    .chip4 {
        border-color: #DB4437;
    }

    .slick-next:before {
        font-family: "Font Awesome 5 Free";
        content: "\f101";
        display: inline-block;
        vertical-align: middle;
        font-weight: 900;
        font-size: 32px;
        width: calc(var(--unit) * 16);
        height: calc(var(--unit) * 26);
        line-height: calc(var(--unit) * 26);
    }

    .slick-prev:before {
        font-family: "Font Awesome 5 Free";
        content: "\f100";
        display: inline-block;
        vertical-align: middle;
        font-weight: 900;
        font-size: 32px;
        width: calc(var(--unit) * 16);
        height: calc(var(--unit) * 26);
        line-height: calc(var(--unit) * 26);
    }

    .slick-next,
    .slick-prev {
        top: 50%;
    }

    .slick-prev {
        left: auto;
        right: 100%;
        width: unset;
        height: unset;
    }

    .slick-next {
        right: auto;
        left: 100%;
        width: unset;
        height: unset;
    }

    .slick-initialized .slick-slide {
        display: flex;
        justify-content: center;
    }
}

