.loadingPage_counter {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .counter_card {
    margin-top: calc(var(--unit) * 6);
    width: 90%;
    height: calc(var(--unit) * 18);
    display: flex;
    align-items: center;
    background: var(--dialog-bg);
    text-shadow: var(--dialog-text-shadow);
    border-radius: var(--border-radius);
    justify-content: center;
    span {
      font-size: 200%;
      line-height: 1;
      display: flex;
      align-items: center;
      text-align: center;
      color: #ff9900;
      &.small {
        font-size: 120%;
      }
    }
  }
}
