.homebaccarat {
    background: var(--game-bg);

    .header {
        background-color: #000000;
    }
}

.select_bet2_winner {
    background: #09ff00 !important;
    border: 2px solid #d9d9d9 !important;
}

.frame {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

// one hour
