@mixin stats {
  .stat_row {
    border: calc(var(--unit-border)) solid var(--game-border-color);
    border-radius: calc(var(--unit) * 2);
    height: calc(var(--unit) * 20);
    overflow: hidden;

    &.progress_stat {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      padding: calc(var(--unit) * 4);
      gap: calc(var(--unit) * 2);
      .label {
        width: calc(var(--unit) * 20);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: calc(var(--unit) * 5);
        text-align: center;

        img {
          width: calc(var(--unit) * 16);
          height: calc(var(--unit) * 8);
        }
      }
      .progress {
        width: calc(var(--unit) * 60);
        border: var(--unit-border) solid  var(--game-border-color);
      }
    }
    &.statistic_chips {
      align-items: center;
    }
  }

  .progress {
    display: flex;
    overflow: hidden;
    font-size: calc(var(--unit) * 4);
    background-color: transparent;
    border-radius: calc(var(--unit) * 1);
    height: calc(var(--unit) * 12);

    .progress-bar {
      padding: 0 calc(var(--unit) * 2);
      &.left { text-align: left; }
      &.right { text-align: right; }
    }

    .progress-bar-striped {
      background-image: unset !important;
    }

    .progressbar_black {
      background-color: var(--game-black) !important;
      color: #FFF !important;
    }

    .progressbar_white {
      background-color: #fff !important;
      color: var(--box-bg-gray) !important;
    }

    .progressbar_blue {
      background-color: #4285F4 !important;
      color: #FFF !important;
    }

    .progressbar_red {
      background-color: #DB4437 !important;
      color: #FFF !important;
    }

    .progressbar_green {
      background-color: var(--game-dark-green) !important;
      border-left: var(--unit-border) solid var(--game-border-color);
      border-right: var(--unit-border) solid var(--game-border-color);
    }
  }

  .chip {
    width: calc(var(--unit) * 9);
    height: calc(var(--unit) * 9);
    font-size: calc(var(--unit) * 4);
    display: flex;
    align-items: center;
    justify-content: center;
    border: var(--unit-border) solid var(--game-border-color);

    color: white;
    border-radius: calc(var(--unit) * 1);
    &.red {
      background: var(--game-red);
    }
    &.black {
      background: var(--game-black);
      color: white !important;
    }
    &.green {
      background: var(--game-dark-green);
    }
  }

  .statistic_chips {
    width: 100%;
    display: flex;
    margin: 0 auto;
    padding: 0 calc(var(--unit) * 4);
    gap: calc(var(--unit) * 1);
    justify-content: space-between;
    flex-wrap: nowrap;
    div:first-child {
      margin-right: auto;
    }
  }

  .statistic_boxes {
    display: flex;
    width: 100%;
    gap: calc(var(--unit) * 0.5);
  }
  .col-box {
    flex-basis: calc(100% / 6);
    height: 100%;
    text-align: center;
    line-height: calc(var(--unit) * 6);
    font-size: calc(var(--unit) * 6);
    &:not(:first-child) {
      border-left: var(--unit-border) solid var(--game-border-color);
    }
    &.w-33 { flex-basis: 33.33%; }
    &.w-50 { flex-basis: 50%; }
  }

  .stat_row.result_form {
    display: flex;
    flex-direction: row;
    .col {
      flex-grow: 1;
      border-right: var(--unit-border) solid var(--game-border-color);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      font-size: calc(var(--unit) * 3);
      &:last-child {
        flex-grow: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: calc(var(--unit) * 6);
        border-right: none;
        span {
          width: calc(var(--unit) * 12);
          height: calc(var(--unit) * 12);
          line-height: calc(var(--unit) * 12);
          text-align: center;
          border: var(--unit-border) solid var(--game-border-color);
          border-radius: var(--border-radius);
        }
      }
    }
  }
}
