#transaction_table {
  .table_header_text,
  .body_list {
    width: 33%;
  }
  .table_header_text:first-child,
  .body_list:first-child {
    width: 34%;
  }
}
