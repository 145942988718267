.label_list {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  min-height: 100px;
  overflow: auto;
  height: 100%;

  .label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .label_text {
      flex: 1 1 auto;
      margin-right: 10px;
      height: 48px;
      background-color: var(--accent-color);
      border-radius: var(--border-radius);
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: #000000;
    }
  }
}
