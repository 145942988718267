:root {
  --dialog-bg: rgba(0, 0, 0, 0.7);
  --dialog-text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
  --app-bg: #666666;
  --game-bg: radial-gradient(#24A062, #1F603F);
  --accent-color: #FF9900;
  --game-border-color: #B0D2B7;
  --box-bg-gray: #434343;
  --button-bg-gray: #666666;
  --border-radius: 10px;
  --unit: 1vw;
  --unit-border: 0.25vw;
  --blue: #4285F4;
  --game-green: #00FF00;
  --game-dark-green: #35654d;
  --game-red: #FF0101;
  --game-black: #000000;
  --roulette-border: #ffffff;
}

html, body {
  height: 100%;
  margin: 0;
}

body {
  display: block;
  justify-content: center;
  align-items: flex-start;
  background-color: #434343;
  color: #FFFFFF;
  font-family: 'Inter';
}

#root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.page_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: calc(var(--unit) * 4);

  .header {
    height: 18vw;
    flex: 0 0 18vw;
  }

  &.game_container {
    .page_content {
      width: 100vw;
      height: 182vw;
      //outline: 3px solid orange;
      //outline-offset: -3px;
      padding: 0;
      .main_layout {
        width: 90vw;
        height: 90vw;
      }
    }
  }
}

.page_content {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 10px;
  align-items: center;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  width: 100%;

  &.no_gap {
    padding: 0;
    gap: 0;
  }

  &.full {
    width: 100% !important;
    height: 100% !important;
    padding: 0;
  }

  .main_layout {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .main_layout_inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
  }

  .list {
    .list-header {
      margin: 10px 10px 0;
    }
    .list-body {
      height: 100%;
      padding: 10px;
    }
    button.more {
      height: 40px;
      line-height: 40px;
      border-radius: var(--border-radius);
      border: none;
    }
  }
}

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

::-webkit-scrollbar-thumb {
  background: #434343;
}

.top_dialog_box {
  position: absolute;
  top: calc(var(--unit) * 6);
  width: 90%;
  height: calc(var(--unit) * 18);
  overflow: auto;
  background: var(--dialog-bg);
  text-shadow: var(--dialog-text-shadow);
  border-radius: var(--border-radius);
  z-index: 100;
}

.center_dialog_box {
  position: absolute;
  top: calc(var(--unit) * 75);
  left: 0;
  right: 0;
  margin: auto;
  transform: translateY(-50%);
  width: 90%;
  max-height: calc(var(--unit) * 100);
  padding: calc(var(--unit) * 6);
  overflow: auto;
  background: var(--dialog-bg);
  text-shadow: var(--dialog-text-shadow);
  border-radius: var(--border-radius);
  z-index: 200;
}

@media (min-aspect-ratio: 1/2) {
  .page_container {
    .header {
      height: 9vh;
      flex: 0 0 9vh;
    }
  }
  .page_container.game_container {
    .page_content {
      width: 100%;
      height: 91vh;
      //outline: 3px solid yellow;
      //outline-offset: -3px;
      .main_layout {
        width: 90%;
        height: 45vh;
      }
    }
  }

  :root {
    --border-radius: 10px;
    --unit: 0.5vh;
    --unit-border: 0.25vh;
  }
}

@media (min-aspect-ratio: 2/3) {
  .page_container.game_container {
    .page_content {
      width: calc(100vh / 3 * 2);
      height: 91vh;
      //outline: 3px solid red;
      //outline-offset: -3px;
    }
  }

  :root {
    --border-radius: 10px;
    --unit: 0.5vh;
    --unit-border: 0.25vh;
  }
}

@import "./Styles/table";

#webpack-dev-server-client-overlay {
  display: none;
}
